import { SharedService } from 'src/app/_services/shared.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterContentInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css', '../inputs.css']
})
export class SelectComponent implements OnInit , OnChanges, AfterContentInit {

  @Input() public label: string;
  @Input() public appearance = 'standard';
  @Input() public options: any[];
  @Input() public value = 'value';
  @Input() public multiText = [];
  @Input() public text = 'text';
  @Input() public icon = '';
  @Input() public compTabIndex = 0;
  @Input() public control: UntypedFormControl = new UntypedFormControl();
  @Input() public defaultValue:  number | string | any;
  @Input() public set disabled(val){
    this._disabled = val;
    this.ngAfterContentInit();
  }
  public get disabled(){
    return this._disabled;
  }
  private _disabled: boolean = false;
  selected = false;
  isOpen = false;
  @ViewChild('select') mySelect;

  @Output() selectedValue: EventEmitter<string|number> = new EventEmitter<string|number>();

  constructor(public _shared: SharedService) {

   }

  ngAfterContentInit(): void {
    this.selected =  this.getselected();
    if (this.disabled) {
      this.control.disable();
    }else {
      this.control.enable();

    }
  }
  setSelectedValue(valueToSet) {
    let item = this.options.filter(x=>x[this.value] == valueToSet);
    if(item.length>0)
      this.setSelectedItem(item[0]);

  }
  setSelectedItem(item) {
    this.control.setValue(item.value);
    this.selected = item;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.control == null && this.options.length > 0) {
      this.control = new UntypedFormControl(this.options[0][this.value]);
      this.selected = this.getSelectedItem(this.options[0][this.value]);
    }
    if (this.defaultValue !== undefined && this.defaultValue !== null  ) {
      this.control.setValue(this.defaultValue);
      this.selected =  this.getSelectedItem(this.defaultValue);
    }
    if (this.disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  setDisabled(flag) {
    if (flag) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  getSelectedItem(value ) {


    for (let index = 0; index < this.options?.length; index++) {
      const element = this.options[index];
      if (element[this.value] == value)  {
        return element;
      }

    }

  }
  getselected() {

    if (this.selected === undefined && this.defaultValue !== undefined) {
       this.selected = this.getSelectedItem(this.defaultValue);
    }
    if (this.selected === undefined && this.options?.length > 0 )  {
       this.selected = this.options[0];
    }

    return this.selected;

  }

  onSelectChange(value: string | number | any): void {
    this.selected = this.getSelectedItem(value);
    this.selectedValue.emit(value);
  }



  ngOnInit(): void {
    this.selected =  this.options?.filter(x => x.value == this.defaultValue)[0];
  }
  close($event) {
    if(this.isOpen) {
      this.mySelect.close();
      $event.stopPropagation()
    }

  }
  openedChange($event) {
    this.isOpen = $event;
  }


}
