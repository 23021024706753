// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-group {
    font-size: var(--font-size--small);
}

.avatar-group-item:not(:first-child) {
    margin-right: -8px;
}

.avatar-group-item:hover {
    z-index: 10;
}

.btn-hidden-avatars {
    width: 30px !important;
    height: 30px !important;
    font-size: var(--font-size--small);
    padding: 0 !important;
    background: var(--purple);
    color: var(--card-background-color);
    margin-right: -8px;
    outline: 2px solid var(--card-background-color);
}

@media (hover: hover) and (pointer: fine) {
    .btn-hidden-avatars:hover {
        border-radius: 50%;
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%), 0 2px 6px 2px rgb(0 0 0 / 15%);
        transform: translateY(-1px);
        cursor: pointer;
        background: var(--purple);
    }
}

.menu-item {
    padding: 8px;
    align-items: center;
    gap: 8px;
}

::ng-deep .cdk-overlay-pane .avatar-menu {
    min-width: 220px;
    max-height: 300px;
}

::ng-deep .btn-hidden-avatars span {
    font-size: unset;
    width: 100%;
    display: inline-block;
}

.more-hidden-items {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/common/UIControls/avatar-group/avatar-group.component.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;AACtC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,kCAAkC;IAClC,qBAAqB;IACrB,yBAAyB;IACzB,mCAAmC;IACnC,kBAAkB;IAClB,+CAA+C;AACnD;;AAEA;IACI;QACI,kBAAkB;QAClB,wEAAwE;QACxE,2BAA2B;QAC3B,eAAe;QACf,yBAAyB;IAC7B;AACJ;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;IACvB,kBAAkB;AACtB","sourcesContent":[".avatar-group {\r\n    font-size: var(--font-size--small);\r\n}\r\n\r\n.avatar-group-item:not(:first-child) {\r\n    margin-right: -8px;\r\n}\r\n\r\n.avatar-group-item:hover {\r\n    z-index: 10;\r\n}\r\n\r\n.btn-hidden-avatars {\r\n    width: 30px !important;\r\n    height: 30px !important;\r\n    font-size: var(--font-size--small);\r\n    padding: 0 !important;\r\n    background: var(--purple);\r\n    color: var(--card-background-color);\r\n    margin-right: -8px;\r\n    outline: 2px solid var(--card-background-color);\r\n}\r\n\r\n@media (hover: hover) and (pointer: fine) {\r\n    .btn-hidden-avatars:hover {\r\n        border-radius: 50%;\r\n        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%), 0 2px 6px 2px rgb(0 0 0 / 15%);\r\n        transform: translateY(-1px);\r\n        cursor: pointer;\r\n        background: var(--purple);\r\n    }\r\n}\r\n\r\n.menu-item {\r\n    padding: 8px;\r\n    align-items: center;\r\n    gap: 8px;\r\n}\r\n\r\n::ng-deep .cdk-overlay-pane .avatar-menu {\r\n    min-width: 220px;\r\n    max-height: 300px;\r\n}\r\n\r\n::ng-deep .btn-hidden-avatars span {\r\n    font-size: unset;\r\n    width: 100%;\r\n    display: inline-block;\r\n}\r\n\r\n.more-hidden-items {\r\n    white-space: nowrap;\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
