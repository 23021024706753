// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attached-file {
  direction: rtl;
  padding: 4px 0;
  display: contents;
}

.mat-icon {
  margin-left: 8px;
  color: var(--file-upload-icon-color);
  vertical-align: middle;
}

.attached-file a:focus {
  outline: none;
}

.file-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.file-name span {
  vertical-align: middle;
}

.number-field {
  direction: ltr;
  unicode-bidi: embed;
}

.extra-data {
  display: inline-block;
  color: var(--file-upload-color);
  display: block;
  padding-right: 30px;
  font-family: var(--boldFont);
  font-weight: bold;
}

.fontDefault {
  font-size: var(--font-size--default) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/common/UIControls/attached-file/attached-file.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,oCAAoC;EACpC,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,+BAA+B;EAC/B,cAAc;EACd,mBAAmB;EACnB,4BAA4B;EAC5B,iBAAiB;AACnB;;AAEA;EACE,+CAA+C;AACjD","sourcesContent":[".attached-file {\r\n  direction: rtl;\r\n  padding: 4px 0;\r\n  display: contents;\r\n}\r\n\r\n.mat-icon {\r\n  margin-left: 8px;\r\n  color: var(--file-upload-icon-color);\r\n  vertical-align: middle;\r\n}\r\n\r\n.attached-file a:focus {\r\n  outline: none;\r\n}\r\n\r\n.file-name {\r\n  overflow: hidden;\r\n  white-space: nowrap;\r\n  text-overflow: ellipsis;\r\n}\r\n\r\n.file-name span {\r\n  vertical-align: middle;\r\n}\r\n\r\n.number-field {\r\n  direction: ltr;\r\n  unicode-bidi: embed;\r\n}\r\n\r\n.extra-data {\r\n  display: inline-block;\r\n  color: var(--file-upload-color);\r\n  display: block;\r\n  padding-right: 30px;\r\n  font-family: var(--boldFont);\r\n  font-weight: bold;\r\n}\r\n\r\n.fontDefault {\r\n  font-size: var(--font-size--default) !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
