import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { VirtualScrollerComponent } from '@iharbeck/ngx-virtual-scroller';
import { TableColumn, TableColumnFooter } from '../tableTypes';
import { SharedService } from 'src/app/_services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/common/snack-bar/snack-bar.component';
import { Sort, SortDirection } from '@angular/material/sort';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-virtual-table',
  templateUrl: './virtual-table.component.html',
  styleUrls: ['./virtual-table.component.css', '../table/table.component.css']
})
export class VirtualTableComponent implements OnInit {

  @Input() dataSource: any[] = [];
  @Input() columnsFooter: TableColumnFooter[] = [];
  @Input() stickyWidth: any[] = [];
  @Input() isEditMode = false;
  @Input() columns: TableColumn[] = [];
  @Input() RowCounter = false;
  @Input() showFooter = false;
  @Input() tableHeight:number = 408;
  @Input() index = '';
  @Input() noData: string = 'noData2';
  @Input() container: any;
  @Input() sortActive = '';
  @Input() sortDirection: SortDirection = 'asc';
  @Output() checkBoxClicked = new EventEmitter<any>();
  @Output() textChanged = new EventEmitter<any>();
  @Output() radioButtonClicked = new EventEmitter<any>();
  @Output() dynamicClicked = new EventEmitter<any>();
  @Output() rowClick = new EventEmitter<any>();
  @Output() loadComplete = new EventEmitter();
  @Output() externalSort = new EventEmitter<any>();
  hover = '';
  hoverIndex = -1;
  showCtl = true;
  selectedRows = []
  isLtr = false;
  constructor(
    public sharedService: SharedService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
  ) {
    this.isLtr = !this.sharedService.isRtl();

  }
  ngOnInit(): void {
    this.columns.forEach((x, index) => {
      if (x.isSticky) {
        let px = 0;
        for (let i = 0; i < index; i++) {
          px += parseInt(this.columns[i].columnWidth.replace('columnWidth', ''))
        }
        this.stickyWidth.push(`${px}px`)
      }
    });

  }

  checkHover(i) {
    if (this.hoverIndex == i) return this.hover;
  }

  showCb(i) {
    if (this.hoverIndex == i || this.isEditMode) return 'showCb';
  }
  isSticky(id: string) {
    return this.columns.filter(x => x.id == id)[0]?.isSticky;
  }

  isLastSticky(id: string) {
    for (let index = 0; index < this.columns.length; index++) {
      const element = this.columns[index];
      if (element.id == id && element.isSticky && !this.columns[index + 1].isSticky) {
        return this.isLtr ? 'last-sticky-ltr' : 'last-sticky';
      }

    }

    return '';

  }



  calcRight(i) {

  }

  sortData(sort: Sort) {
    if (this.externalSort.observers.length > 0) {
      this.externalSort.emit(sort)
    } else {

      this.dataSource = this.dataSource.sort((a, b) =>
        sort.direction == 'asc' ?
          this.sharedService.compare(a[sort.active], b[sort.active]) :
          this.sharedService.compare(b[sort.active], a[sort.active])
      );
    }
  }

  textboxChanged($event, element, column, ctl: UntypedFormControl = null) {
    if (column.type == 'numberTextWithIcon') {
      $event.value = this.numberTextWithIconBlur(column, $event.value)
      ctl.setValue($event.value);
      (document.getElementsByClassName($event.classList)[0] as any).value = $event.value;
    }
    this.dynamicClicked.emit({
      eventClick: 'textChanged',
      data: {
        text: $event.value ?? $event,
        element: element,
        column: column.id
      }
    });
  }

  getControl(element, column, test = '') {
    // if(test !='') console.log(element)
    if (element['events'] !== undefined) {
      return (element['events'][column.id])
    } else {
      return (element[column.id])
    }
  }

  getControlById(element, column) {
    if (element['events'] !== undefined) {
      return (element['events'][column])
    } else {
      return (element[column])
    }
  }

  getBadgeValue(element, column) {
    if (element['badges'] !== undefined) {
      return (element['badges'][column.id])
    }
  }


  mouseenter(i) {
    this.hoverIndex = i;
    this.hover = 'rowHover';
  }
  mouseleave() {
    this.hoverIndex = -1;
    this.hover = '';
  }

  rowSelected(i, $event) {
    let index = this.dataSource[i][this.index]
    if ($event)
      this.selectedRows.push(index);
    else
      this.selectedRows = this.selectedRows.filter(x => x != index);
  }

  selectedRowsExist(i) {
    if (this.dataSource[i] != null) {
      let index = this.dataSource[i][this.index]
      return this.selectedRows.filter(x => x == index).length > 0;
    }
    return 0;
  }

  headerClicked($event, element, column) {
    this.dataSource.forEach(elem => {
      elem.events[column.id].setValue($event);
      this.eventChecked($event, elem, column);
    });
  }
  dynamicClick(eventClick, data) {
    this.dynamicClicked.emit({
      eventClick: eventClick,
      data: data
    })

  }
  numberTextWithIconBlur(column, value) {

    if (!this.sharedService.isEmpty(column.minValue)) {
      if (value < column.minValue || value > column.maxValue) {

        const text = this.translate.instant('MinMaxGrade').replace('{MinValue}', column.minValue).replace('{MaxValue}', column.maxValue);
        this.snackBar.openFromComponent(SnackBarComponent, {
          duration: 3000,
          data: { message: text, type: 'warning' }
        });
        return '';
      }
    }
    return value;
  }

  eventChecked($event, element, column) {

    this.checkBoxClicked.emit({
      status: $event,
      element: element,
      column: column
    });


  }

  radioButtonCheck($event, element, column) {
    this.radioButtonClicked.emit({
      radioCheck: $event,
      element: element,
      column: column
    });
  }

  footerData;
  getFooterByIndex(index) {
    this.footerData = this.columnsFooter.filter(x=>x.columnIndex == index)[0];
    return (this.footerData);
  }

  @ViewChild('scroll') scroller: VirtualScrollerComponent;

  onVsUpdate(event) {
    console.log('vs update', event);
  }

  onVsChange(event) {
    console.log('vs change', event);
  }

  onVsStart(event) {
    console.log('vs start', event);
  }

  onVsEnd(event) {
    console.log('vs end', event);
  }
}
