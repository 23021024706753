import { Component, Input } from '@angular/core';
import { MobileService } from 'src/app/_services/mobile-service.service';
import { SharedService } from 'src/app/_services/shared.service';
import { UserService } from 'src/app/_services/user-service.service';

export interface AvatarItem {
  id: string;
  firstName: string;
  lastName: string;
  userImageToken: string;
}

@Component({
  selector: 'app-avatar-group',
  templateUrl: './avatar-group.component.html',
  styleUrls: ['./avatar-group.component.css']
})
export class AvatarGroupComponent {
  constructor(public sharedService: SharedService,
    private userService: UserService,
    public mobileService: MobileService
  ) { this.institutionCode = this.userService.getUser().institutionCode; }
  @Input() maxVisible = 3;
  @Input() idField: string;
  @Input() fNameField: string;
  @Input() lNameField: string;
  @Input() userTypeField: string;
  @Input() tokenField: string;
  @Input() size: string;
  @Input() set avatarsArray(value: any[]){
    if (value == null || value?.length == 0)
      return;
    this.avatars = value.map(item => ({
      id: item[this.idField],
      firstName: item[this.fNameField],
      lastName: item[this.lNameField],
      userImageToken: item[this.tokenField],
      userType: item[this.userTypeField]
    }));
    this.hiddenCount =  this.avatars.length > this.maxVisible ? this.avatars.length - this.maxVisible : 0;
    this.visibleAvatars = this.avatars.slice(0, this.maxVisible);
    this.hiddenAvatars = this.avatars.slice(this.maxVisible);
  }
  avatars: AvatarItem[];
  visibleAvatars: AvatarItem[];
  hiddenAvatars: AvatarItem[];
  institutionCode: number;
  hiddenCount: number;
}
