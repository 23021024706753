import { MobileService } from './../../../_services/mobile-service.service';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-alert-transparent',
  templateUrl: './alert-transparent.component.html',
  styleUrls: ['./alert-transparent.component.css']
})
export class AlertTransparentComponent implements OnInit {

  @Input() cssClass = '';
  @Input() showMoreClass = '';
  @Input() isOuter: boolean = false;

  hasOverFlow: boolean;
  showMore: boolean = false;
  @ViewChild('content', { read: ElementRef, static: false }) content: ElementRef;

  constructor(private mobileService: MobileService) {
    mobileService.getIsMobile().pipe().subscribe(status => {
      this.checkContent();
    })
  }

  ngAfterViewInit(): void {
    this.checkContent();
  }

  checkContent() {
    if (this.content == undefined ) {
      return;
    }
    if ( this.mobileService.isMobileView && (
      this.content.nativeElement.offsetHeight < this.content.nativeElement.scrollHeight ||
      this.content.nativeElement.offsetWidth < this.content.nativeElement.scrollWidth)) {
      this.hasOverFlow = true;
      //this.showMore = true;
    } else {
      this.showMore = false;
      this.hasOverFlow = false;
    }
  }

  ngOnInit(): void {
  }
}
