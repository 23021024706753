import { PushNotificationComponent } from './push-notification/push-notification.component';
import { FirstPasswordDialogComponent } from './app-nav/first-password-dialog/first-password-dialog.component';
import { SettingsModule } from './settings/settings.module';
import { AddCsrfHeaderInterceptorService } from './_services/xCsrfInterceptor';
import { CodeInputControlModule } from './common/UIControls/code-input-control/code-input.module';
import { UIModule } from './modules/UI.module';
import { PipesModule } from './modules/pipes.module';
import { SidenavService } from './_services/SidenavService';
import { UnreadNotificationsPreviewComponent } from './app-nav/unread-notifications-preview/unread-notifications-preview.component';
import { UnreadMessagesPreviewComponent } from './app-nav/unread-messages-preview/unread-messages-preview.component';
import { SharedModule } from './shared.module';
import { Injectable, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, Title } from '@angular/platform-browser';
import { UserService } from './_services/user-service.service';
import { I18nModule, translateLoaderFactory } from './i18n/i18n.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderService } from './_services/LoaderService';
import { LoaderInterceptor } from './_services/LoaderInterceptor';
import { LayoutModule } from '@angular/cdk/layout';
import { PostService } from './_services/postService';
import { AppNavComponent } from './app-nav/app-nav.component';
import { EmptyComponent } from './empty/empty.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { PupilCardSharedService } from './_services/PupilCardSharedService';
import { StoreModule } from '@ngrx/store';
import { FormsModule } from '@angular/forms';
import { provideLottieOptions } from 'ngx-lottie';
import { CookieModule } from 'ngx-cookie';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
import { NgxPullToRefreshModule } from 'ngx-pull-to-refresh';
//import { ReportsPyramidListComponent } from './reportsPyramid/reports-pyramid-list/reports-pyramid-list.component';
//import { reportsPyramidReportComponent } from './reportsPyramid/reports-pyramid-report/reports-pyramid-report.component';


const dbConfig: DBConfig = {
  name: 'WebTop',
  version: 1,
  objectStoresMeta: [{
    store: 'storage',
    storeConfig: { keyPath: 'key', autoIncrement: false },
    storeSchema: [
      { name: 'value', keypath: 'value', options: { unique: false } },
      { name: 'expireDate', keypath: 'expireDate', options: { unique: false } }
    ]
  }]
};


@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      },
      isolate: true
    }),
    I18nModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LayoutModule,
    PipesModule,
    UIModule,
    FormsModule,
    CodeInputControlModule,
    SettingsModule,
    StoreModule.forRoot({}, {}),
    CookieModule.withOptions(),
    NgxIndexedDBModule.forRoot(dbConfig),
    NgxPullToRefreshModule
  ],
  declarations: [
    AppComponent,
    AppNavComponent,
    UnreadMessagesPreviewComponent,
    UnreadNotificationsPreviewComponent,
    EmptyComponent,
    PushNotificationComponent,
    FirstPasswordDialogComponent,
    //ReportsPyramidListComponent,
    //reportsPyramidReportComponent
    
  ],
  providers: [
    provideLottieOptions({
      player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web'),
    }),
    PostService,
    UserService,
    Title,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (service: LoaderService) => new LoaderInterceptor(service),
      multi: true,
      deps: [LoaderService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: AddCsrfHeaderInterceptorService, multi: true },
    SidenavService, PupilCardSharedService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
