import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-table-row-expand',
  templateUrl: './table-row-expand.component.html',
  styleUrls: ['./table-row-expand.component.css'],
  viewProviders: [MatExpansionPanel]
})
export class TableRowExpandComponent implements OnInit {

  @Input() isActive: boolean = false;
  @Input() disabled: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
