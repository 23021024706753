import { response } from 'express';
import { SharedService } from 'src/app/_services/shared.service';
import { UntypedFormControl } from '@angular/forms';
import { Component, Input, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { UserService } from 'src/app/_services/user-service.service';
import { PostService } from 'src/app/_services/postService';
import { HttpHeaders } from '@angular/common/http';
import { MobileService } from 'src/app/_services/mobile-service.service';
// import { EditorComponent } from '@tinymce/tinymce-angular'
declare const tinymce: any;

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.css']
})
export class TextEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  public editorOptions: object;

  public showEditor = false;

  @Input() control: UntypedFormControl = new UntypedFormControl();
  constructor(
    private sharedService: SharedService,
    private user: UserService,
    private post: PostService,
    private mobileService: MobileService
  ) { }
  ngOnDestroy(): void {
    this.editorOptions = null;

  }
  ngAfterViewInit(): void {

  }


  ngOnInit(): void {
    let toolbar = this.mobileService.isMobileView ?
      'bold italic underline strikethrough | alignleft aligncenter alignright alignjustify' :
      'undo redo | fontfamily fontsize blocks | searchreplace print | forecolor backcolor removeformat | bold italic underline strikethrough subscript superscript | alignleft aligncenter alignright alignjustify | outdent indent | rtl ltr | numlist bullist | image link charmap emoticons hr table |';
    let lang = 'he_IL';
    let userLang = localStorage.getItem('language');
    switch (userLang) {
      case 'ar':
        lang = 'ar'
        break;
      case 'ru':
        lang = 'ru'
        break;
      case 'en':
      case 'ti':
        lang = '';
        break;
      default:
        break;
    }

    let defaultColor = localStorage.getItem('darkTheme') == '2' ? 'rgb(255 255 255 / 87%)' : '';

    this.editorOptions = {
      license_key: 'gpl',
      height: 400,
      directionality: this.sharedService.isRtl() ? 'rtl' : 'ltr',
      language: lang,//this.sharedService.isRtl() ? 'he_IL' : '',
      menubar: false,
      statusbar: false,
      skin: (localStorage.getItem('darkTheme') != null ? "oxide-dark" : "oxide"),
      /* content_css: (localStorage.getItem('darkTheme') != null ? "dark" : "light"),*/
      plugins: [

        'autolink', 'advlist', 'lists', 'link', 'image', 'table',
        'searchreplace', 'visualblocks',
        'insertdatetime',
        'charmap', ' emoticons', 'directionality', 'quickbars '
      ],
      toolbar: toolbar,// 'undo redo | fontfamily fontsize blocks | searchreplace print | forecolor backcolor removeformat | bold italic underline strikethrough subscript superscript | alignleft aligncenter alignright alignjustify | outdent indent | rtl ltr | numlist bullist | image link charmap emoticons hr table |',
      quickbars_selection_toolbar: 'cut copy paste',
      quickbars_insert_toolbar: false,
      toolbar_mode: 'wrap',
      content_style: `
            body {
              color:${defaultColor};
            }

            `,
      quickbars_image_toolbar: 'imagetools',
      target_list: false,
      link_default_target: '_blank',
      mobile: {
        toolbar: toolbar,
        toolbar_mode: 'wrap'
      },
      setup: function (editor) {
        var newColor = document.createElement("style");
        document.head.appendChild(newColor);
        newColor.sheet.insertRule("#editor {background: blue}")
        editor.on('change', function (e) {
          console.log('change');
          console.log(e)
          // unSavedDataOnNewMessage = true;
        });
      },
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.onchange = function () {
          var file = input.files[0];
          var reader = new FileReader();

          // FormData
          var fd = new FormData();
          var files = file;
          fd.append('filetype', meta.filetype);
          fd.append("file", files);
          fd.append("fileName", file.name)
            ;


          var filename = "";

          // AJAX
          var xhr, formData;
          xhr = new XMLHttpRequest();
          xhr.withCredentials = false;
          const httpOptions = {
            headers: new HttpHeaders({
            }),
            withCredentials: true,
            //observe: 'response' as 'body'
          };
          var apiUrl = '';
          switch (window.location.host.toLowerCase()) {
            case '192.168.4.42:4200':
            case '127.0.0.1:4200':
            case 'localhost:4200':
              apiUrl = 'https://localhost:44389';
              break;
            case 'testclient.smartschool.co.il':
              apiUrl = 'https://testserver.smartschool.co.il/server';
              break;
            case 'testbrance.smartschool.co.il':
              apiUrl = 'https://testserver.smartschool.co.il/serverBranch';
              break;
            case 'webtop.smartschool.co.il':
              apiUrl = 'https://webtopserver.smartschool.co.il/server';
              break;
          }

          xhr.open('POST', `${apiUrl}/api/stream/UploadFile`, true);
          xhr.withCredentials = true;
          xhr.onload = function () {
            var json;
            if (xhr.status != 200) {
              alert('HTTP Error: ' + xhr.status);
              return;
            }
            /* json = JSON.parse(xhr.responseText);
            if (!json || typeof json.location != 'string') {
                alert('Invalid JSON: ' + xhr.responseText);
                return;
            } */
            if (xhr.responseText == "Fail") {
              alert('קובץ לא תקין');
              return;
            }
            filename = xhr.responseText;
            reader.onload = function (e) {
              cb(filename, { title: file.name, text: file.name });
            };
            reader.readAsDataURL(file);
          };

          xhr.send(fd, httpOptions);
          return
        };

        input.click();
      }


    };
    setTimeout(() => {
      this.showEditor = true;
    }, 50);
  }

  setValue(str: string) {
    this.control.setValue(str);
  }
  getValue(): string {

    return this.control.value;
  }


  setEditorFocus() {
    var editors = document.getElementsByTagName('iframe');
    if (editors !== undefined && editors.length > 0) {
      var editor = editors[0];
      var body = editor.contentDocument.getElementsByTagName('body');
      if (body !== undefined && body.length > 0) {
        body[0].focus();
      }
    }
  }





}
/*
file_picker_callback: (cb, value, meta) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');

    input.addEventListener('change', (e) => {
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        / *
          Note: Now we need to register the blob in TinyMCEs image blob
          registry. In the next release this part hopefully won't be
          necessary, as we are looking to handle it internally.
        * /
          const id = 'blobid' + (new Date()).getTime();
          const blobCache =  tinymce.activeEditor.editorUpload.blobCache;
          const base64 = reader.result.split(',')[1];
          const blobInfo = blobCache.create(id, file, base64);
          blobCache.add(blobInfo);

          / * call the callback and populate the Title field with the file name * /
          cb(blobInfo.blobUri(), { title: file.name });
        });
        reader.readAsDataURL(file);
      });

      input.click();
    },
*/
