import { SafeHtmlPipe } from './../../../_pipes/safeHtml';
import { MobileService } from 'src/app/_services/mobile-service.service';
import { SharedService } from 'src/app/_services/shared.service';
import { FullBankComponent } from './full-bank/full-bank.component';
import { MatDialog } from '@angular/material/dialog';
import { AssesmentBank } from './../../../_models/AssesmentBank';
import { PostService } from './../../../_services/postService';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith, take } from 'rxjs/operators';
import { radioBtnOption } from '../radio-buttons-group/radioBtnOption';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../../snack-bar/snack-bar.component';


@Component({
  selector: 'app-assessment-bank',
  templateUrl: './assessment-bank.component.html',
  styleUrls: ['./assessment-bank.component.scss', '../inputs.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssessmentBankComponent implements OnInit {

  @Input() label: string = '';
  @Input() showValueOnSelect = true;
  @Input() placeHolder: string = '';
  @Input() splitByGroupText: string = '';
  @Input() isMale: boolean = true;
  @Input() allowFreeText: boolean = true;
  @Input() allowRemove: boolean = false;
  @Input() id: string = '';
  @Input() appearance="standard";

  skipFilter = false;
  groupOptions: Observable<any[]>;

  private _objectID: any = false;
  @Input() set objectID(value: any) {
    this._objectID = value;
  }

  get objectID(): any {
    return this._objectID;
  }

  private _disabled: boolean = false;
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    if (this.disabled)
      this.control.disable();
    else this.control.enable();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  private _bank: AssesmentBank[];
  @Input() set bank(value: AssesmentBank[]) {
    if (value != undefined && value != null) {
      this._bank = [...value];
      if (this.openBank)
        this.openFullBank();
    }
  }

  get bank(): AssesmentBank[] {
    return this._bank;
  }

  @Input() set value(value: string) {
    this.control.setValue(this.safeHtml.transform(value));
  }

  //@Input() value: string;
  @Input() itemsBank: any[];
  @Input() itemValue: any;
  @Input() itemText: any;
  @Input() itemTextFemale = 'femaleAssesment';
  @Input() pageTitle: any;
  @Input() cssClass = '';
  @Input() hideLabel = true;
  @Input() IsEdit = true;
  @Input() showFromMark = false;

  @Output() selectedAssesment = new EventEmitter<any | number>();

  control: UntypedFormControl = new UntypedFormControl();
  filteredOptions: Observable<AssesmentBank[]>;
  searchOnServer: boolean = true;
  openBank: boolean = false;
  currentObjectBank: any;
  listOptions: any[];
  constructor(
    private postSrv: PostService,
    private matDialog: MatDialog,
    private shared: SharedService,
    public mobileService: MobileService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private cdRef: ChangeDetectorRef,
    private safeHtml: SafeHtmlPipe

  ) {
  }

  ngOnInit() {
    this.skipFilter = true;
    if ((this.bank === undefined || this.bank === null || this.bank.length === 0) && this.itemsBank !== null) {
      this.bank = [];

      this.itemsBank?.forEach(i => {
        const tmp = new AssesmentBank();
        tmp.maleAssesment = i[this.itemText]
        tmp.femaleAssesment = i[this.itemTextFemale] ?? tmp.maleAssesment;
        tmp.from_mark = i[this.itemValue];
        tmp.table_no = 0;
        tmp[this.splitByGroupText] = i[this.splitByGroupText];
        this.bank.push(tmp);
      });
    }
    this.getItemsByGroupText();
    if (this.listOptions.length < 2) {

      this.filteredOptions = this.control.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    } else {
      this.groupOptions = this.control!.valueChanges.pipe(
        startWith(''),
        debounceTime(500),
        map(value => this._filterGroup(value || '')),
      );
    }
    setTimeout(() => {
      this.skipFilter = false;
    }, 350);
  }

  setItemsBank(data) {
    setTimeout(() => {

      this.itemsBank = data;
      this.ngOnInit();
      this.cdRef.detectChanges();
    }, 100);
  }

  private _filterGroup(value: string): any[] {
    if (value) {
      return this.listOptions
        .map(group => ({ header: group.header, items: this._filter(value, group.items) }))
        .filter(group => group.items?.length > 0);
    }
    return this.listOptions;
  }

  getItemsByGroupText() {
    this.listOptions = [];
    if (this.splitByGroupText == '') {
      this.listOptions.push({ items: this.bank })
    } else {
      const headers = this.shared.distinctArray(this.bank, [this.splitByGroupText])
      headers.forEach(x => {
        this.listOptions.push({
          items: this.bank.filter(o => o.category == x[this.splitByGroupText]),
          header: x[this.splitByGroupText]
        })
      });
      return this.listOptions;
    }

  }

  onBlur() {
    setTimeout(() => {

      if (this.bank?.length == 0) return;
      if (!this.shared.isEmpty(this.control?.value) && !this.allowFreeText) {
        if (this.bank.filter(x => this.safeHtml.transform(`${x.from_mark} - ${x.femaleAssesment}` == this.control.value) || this.safeHtml.transform(`${x.from_mark} - ${x.maleAssesment}`) == this.control.value).length == 0) {
          this.snackBar.openFromComponent(SnackBarComponent, {
            duration: 3000,
            data: { message: this.translate.instant('selectFromBank'), type: 'warning' }
          });
          this.control.setValue('');
        }
      }
    }, 200);
  }

  private _filter(value: string, items?: any): AssesmentBank[] {
    if (this.skipFilter) {
      return null;
    }
    if (items == undefined || items == null) {
      items = this.bank;
    }
    const filterValue = value?.toString().toLowerCase();
    let res = items.filter(option =>
      option.from_mark?.toString().includes(value)
      || (this.isMale ? option.maleAssesment : option.femaleAssesment).toLowerCase().includes(filterValue)
      || (this.isMale ? option.maleAssesment : option.femaleAssesment).toLowerCase().includes(this.shared.replcaeLanguage(filterValue))
    );
    return res;

  }

  setSelectedText(value: number, text: string) {
    this.control.setValue(this.safeHtml.transform(`${this.showValueOnSelect && value != null ? (value.toString() + ' - ') : ''}${text}`));
    this.selectedAssesment.emit({ val: value, text: text });
  }

  selected(value: number) {
    this.skipFilter = true;
    const option = this.bank.find(x => x.from_mark === value);
    this.setSelectedText(option.from_mark, this.isMale ? option.maleAssesment :
      !this.shared.isEmpty(option.femaleAssesment) ? option.femaleAssesment : option.maleAssesment);
    setTimeout(() => {
      this.skipFilter = false;
    }, 300);
  }

  onKeyUp() {
    if (this.control?.value?.length == 0)
      this.selectedAssesment.emit(null);
  }

  setIsEdit() {
    this.IsEdit = true;
  }

  onFocus() {
    if (!this.mobileService.isMobileView) {
      if (this.currentObjectBank != this.objectID) {
        this.currentObjectBank = this.objectID;
        return true
      }
    }
    return false;
  }

  openFullBank(returnValue = false) {
    this.openBank = false;
    this.IsEdit = true;
    const options = this.bank.map(x => {
      return {
        "value": x.from_mark,
        "text": `${x.from_mark} - ${this.isMale || this.shared.isEmpty(x.femaleAssesment) ? x.maleAssesment : x.femaleAssesment}`,
        "maleText": `${x.from_mark} - ${x.maleAssesment}`,
        "femaleText": `${x.from_mark} - ${this.shared.isEmpty(x.femaleAssesment) ? x.maleAssesment : x.femaleAssesment}`,
        'category': x.category
      }
    });
    let tmp = null;
    if (!this.shared.isEmpty(this.control.value)) {
      tmp = Number(this.control.value.split(" ")[0]);
      if (isNaN(tmp)) {
        tmp = this.searchInList(this.control.value, options);
      }
    }

    const dialogRef = this.matDialog.open(FullBankComponent, {
      disableClose: true,
      data: {
        options: options,
        selectedAssesment: tmp,
        pageTitle: this.shared.isEmpty(this.pageTitle) ? '' : this.pageTitle,
        splitByGroupText: this.splitByGroupText,
        allowRemove: this.allowRemove
      },
    });
    if (returnValue) {
      return dialogRef;
    } else {
      dialogRef.afterClosed().pipe(take(1)).subscribe((selected: radioBtnOption | boolean) => {
        this.searchOnServer = false;
        if (selected == false)
          return;
        if (selected != null) {
          this.skipFilter = true;
          const option = this.bank.find(x => x.from_mark === selected["value"]);
          this.setSelectedText(selected["value"], this.isMale || this.shared.isEmpty(option.femaleAssesment) ? option.maleAssesment : option.femaleAssesment);
          setTimeout(() => {
            this.skipFilter = false;
          }, 500);
          //this.selectedAssesment.emit(selected["value"]);
        }
        else {
          this.control.setValue("");
          this.selectedAssesment.emit(null);
        }
      });
    }
  }
  searchInList(value: any, options: { value: number; text: string; }[]): any {
    for (let index = 0; index < options.length; index++) {
      const element = options[index];
      let tmp = element.text.replace(`${element.value} - `, '');
      if (tmp == value) {
        return element.value;
      }


    }
  }


}
