import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-iframe-dialog',
  templateUrl: './iframe-dialog.component.html',
  styleUrls: ['./iframe-dialog.component.css']
})
export class IframeDialogComponent {
  url:any;
  constructor(
    public sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<IframeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string;
      url: string,

    }
  ) {
    this.url = sanitizer.bypassSecurityTrustResourceUrl(data.url)
    this.dialogRef.addPanelClass('edit-modal');
  }
}
